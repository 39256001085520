import React from "react";
import FontAwesome from "@fortawesome/fontawesome-free";
import imagem1 from "../../imagem/logotrembao.png";
import imagem2 from "../../img/site_em_construcao (sem cones).png";
import "./Land.css";

export default function Land(){

  return (
    <div className="principal">

    <script
    url= "https://kit.fontawesome.com/c498d5cfb3.js"
    />
     <div class="img">
     <img src = {imagem1} />
     <img src = {imagem2} />
     </div>

      <div className="textArea">
					Estrada Alberto Hinoto, Nº 6953 <br/> BOX 1 A <br/>
					CEP: 08.586-415 <br/>Bairro: Jardim Nascente <br/>
					Cidade - Itaquaquecetuba - SP <br/>
					Telefone: (11) 95822-0350 <br/><br/>

         </div>
         <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.min.css" />

         <a class="whatsApp" href="https://api.whatsapp.com/send?phone=5511958220350" target="_blank" >
         <i class="fa fa-whatsapp"></i>
         </a>

       </div>

  );
}
