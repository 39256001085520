import React from "react";
import Routes from "./routes";

const App = () => (

    <div className="App">
     <Routes />
    </div>
  );

export default App;
